<template>
  <div class="container text-center">
    <div id="OmgevingCarousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#OmgevingCarousel"
          data-bs-slide-to="0"
          class="active"
          aria-label="Slide 1"
          aria-current="true"
        ></button>
        <button type="button" data-bs-target="#OmgevingCarousel" data-bs-slide-to="1" aria-label="Slide 2" class=""></button>
        <button type="button" data-bs-target="#OmgevingCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_5744-2.jpg" style="max-height: 800px" />
        </div>
        <div class="carousel-item">
          <img class="bd-placeholder-img d-block w-100" src="../assets/oranjezon.jpg" style="max-height: 800px" />
        </div>
        <div class="carousel-item">
          <img class="bd-placeholder-img d-block w-100" src="../assets/r.jpg" style="max-height: 800px" />
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#OmgevingCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#OmgevingCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  <div class="container mt-5 mb-5 text-center">
    <div class="row">
      <div class="col-md-4">
        <span class="fa-stack fa-5x">
          <i class="far fa-circle fa-stack-2x"></i>
          <i class="fas fa-map-marker-alt fa-stack-1x"></i>
        </span>

        <h2>Centraal</h2>
        <p>
          Serooskerke ligt centraal op het eiland Walcheren. Hof Buitenlust ligt aan de rand van een typisch Zeeuws dorpje, met
          alle faciliteiten binnen handbereik.
        </p>
      </div>
      <div class="col-md-4">
        <span class="fa-stack fa-5x">
          <i class="far fa-circle fa-stack-2x"></i>
          <i class="fas fas fa-tree fa-stack-1x"></i>
        </span>

        <h2>Natuurlijk</h2>
        <p>
          Hof Buitenlust ligt in een prachtige fietsomgeving. Door de centrale ligging van het appartement, kunt u stad, bos en
          strand makkelijk op de fiets bereiken.
        </p>
      </div>
      <div class="col-md-4">
        <span class="fa-stack fa-5x">
          <i class="far fa-circle fa-stack-2x"></i>
          <i class="fas fa-utensils fa-stack-1x"></i>
        </span>

        <h2>Ontspannen</h2>
        <p>
          Even eruit? Op loopafstand van Hof Buitenlust bevindt zich de alom bekende Pannenkoek- en Speelboerderij Molen de Jonge
          Johannes.
        </p>
      </div>
    </div>
    <hr class="featurette-divider col-md-10 mx-auto mt-5" />
    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading" id="centraal">
          Serooskerke
          <span class="text-muted">Typisch Zeeuws dorpje</span>
        </h2>
        <p class="lead">
          Serooskerke ligt centraal op het eiland Walcheren. Hof Buitenlust ligt aan de rand van een typisch Zeeuws dorpje, met
          alle faciliteiten binnen handbereik. De supermarkt PLUS is op loopafstand. ’s Zomers is het zwembad de Goudvijver in
          Serooskerke geopend. In tien minuutjes bent u met de auto in Middelburg. En op 4 kilometer afstand ligt het strand van
          Vrouwenpolder. Wat wilt u nog meer?
        </p>
      </div>
      <div class="col-md-5">
        <img
          class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto zooming-image"
          width="500px"
          height="500px"
          src="../assets/serooskerke.jpg"
        />
      </div>
    </div>
    <hr class="featurette-divider col-md-10 mx-auto mt-5" />
    <div class="row featurette">
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading" id="natuurlijk">
          Stad, bos en strand makkelijk op de fiets te bereiken
          <!-- <span class="text-muted">Stad, bos en strand makkelijk op de fiets bereiken</span> -->
        </h2>
        <p class="lead">
          Hof Buitenlust ligt in een prachtige fietsomgeving. Door de centrale ligging van het appartement, kunt u stad, bos en
          strand makkelijk op de fiets bereiken. Bestijg duinpaadjes en geniet van weidse uitzichten op het Zeeuwse Walcheren.
          <br />1) Natuurgebied Oranjezon Hét natuurgebied van Walcheren. Oranjezon is een wandelgebied waarbij bos, duingrasland
          en water zich afwisselen. De kans om oog in oog te staan met een damhert of ree is in Oranjezon heel groot. Voor meer
          informatie zie :
          <a href="https://www.hetzeeuwselandschap.nl/sites/default/files/uploads/brochureOranjezon.pdf" target="_blank"
            >Brochure Oranjezon </a
          ><br />
          2) Wandelpad “Rondje Seroos”
        </p>
      </div>
      <div class="col-md-5 order-md-1">
        <img
          class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto zooming-image"
          width="500px"
          height="500px"
          src="../assets/welgelegen.jpg"
        />
      </div>
    </div>
    <hr class="featurette-divider col-md-10 mx-auto mt-5" />
    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading" id="ontspannen">Even eruit?</h2>
        <p class="lead">
          Even eruit? Op loopafstand van Hof Buitenlust bevindt zich de alom bekende Pannenkoek- en Speelboerderij Molen de Jonge
          Johannes. Daarnaast is er op het dorp Brasserie de Lindeboom waar u heerlijk kunt eten en genieten van Zeeuwse
          lekkernijen. Meer zin om actief te ontspannen? Daag jezelf uit bij midgetgolfbaan het Witte Schip in Zoutelande. Of
          bekijk Walcheren in het klein in speelpark Mini Mundi in Middelburg.
        </p>
      </div>
      <div class="col-md-5">
        <img
          class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto zooming-image"
          width="500px"
          height="500px"
          src="../assets/Foto-De-Jonge-Johannes5-e1497293283452.jpg"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
#centraal,
#natuurlijk {
  scroll-margin-top: 4rem;
}
</style>
